var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-1", attrs: { "no-body": "" } },
        [
          _c("b-card-header", [
            _c(
              "div",
              [
                _c("b-card-title", { staticClass: "mb-1" }, [
                  _vm._v(" Dados gerais "),
                ]),
                _c("b-card-sub-title", [
                  _vm._v(
                    " Os dados inseridos abaixo serão utilizados ao emitir a nota fiscal. "
                  ),
                ]),
              ],
              1
            ),
          ]),
          _c("b-card-body", { staticClass: "pl-2 pr-2" }, [
            _c("div", { staticClass: "form-row" }, [
              _c("div", { staticClass: "col-md-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                      }),
                      _vm._v(" Fornecedor "),
                    ]),
                    _c(
                      "v-select",
                      {
                        class: { "is-invalid": _vm.$v.item.provider.$error },
                        attrs: {
                          label: "title",
                          "item-text": "title",
                          "item-value": "code",
                          placeholder: "Digite o nome",
                          options: _vm.optionsProviders,
                        },
                        on: { search: _vm.fetchProviders },
                        model: {
                          value: _vm.item.provider,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "provider", $$v)
                          },
                          expression: "item.provider",
                        },
                      },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "no-options" }, slot: "no-options" },
                          [_vm._v(" Nenhum registro encontrado ")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "col-md-2" }, [
                _c("div", { staticClass: "form-group mb-0 mb-m-1" }, [
                  _c("label", { attrs: { for: "" } }, [
                    _c("i", { staticClass: "text-danger bi bi-record-circle" }),
                    _vm._v(" Vencimento "),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.issue_date,
                        expression: "item.issue_date",
                      },
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: ["##/##/####"],
                        expression: "['##/##/####']",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "dd/mm/aaaa" },
                    domProps: { value: _vm.item.issue_date },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.item, "issue_date", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                      }),
                      _vm._v(" Categoria "),
                    ]),
                    _c(
                      "v-select",
                      {
                        class: { "is-invalid": _vm.$v.item.provider.$error },
                        attrs: {
                          label: "title",
                          "item-text": "title",
                          "item-value": "code",
                          placeholder: "Digite o nome",
                          options: _vm.optionsProviders,
                        },
                        on: { search: _vm.fetchProviders },
                        model: {
                          value: _vm.item.provider,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "provider", $$v)
                          },
                          expression: "item.provider",
                        },
                      },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "no-options" }, slot: "no-options" },
                          [_vm._v(" Nenhum registro encontrado ")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "col-md-2" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v(" Nº Documento "),
                    _c("i", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.top",
                          value: "Referenciar documento fora da Seducar.",
                          expression:
                            "'Referenciar documento fora da Seducar.'",
                          modifiers: { hover: true, top: true },
                        },
                      ],
                      staticClass: "bi bi-question-circle",
                    }),
                  ]),
                  _c("input", {
                    staticClass: "form-control",
                    attrs: { type: "text" },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "col-md-2" }, [
                _c("div", { staticClass: "form-group mb-0 mb-m-1" }, [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v(" Data de emissão "),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.issue_date,
                        expression: "item.issue_date",
                      },
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: ["##/##/####"],
                        expression: "['##/##/####']",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "dd/mm/aaaa" },
                    domProps: { value: _vm.item.issue_date },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.item, "issue_date", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("b-card-header", [
            _c(
              "div",
              [
                _c("b-card-title", { staticClass: "mb-1" }, [
                  _vm._v(" Observações gerais "),
                ]),
                _c("b-card-sub-title", [
                  _vm._v(
                    " Caso tenha mais alguma informação ou observação sobre a empresa "
                  ),
                ]),
              ],
              1
            ),
          ]),
          _c("b-card-body", { staticClass: "pl-2 pr-2" }, [
            _c("div", { staticClass: "form-row" }, [
              _c("div", { staticClass: "col-md-9" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.note,
                        expression: "item.note",
                      },
                    ],
                    staticClass: "d-block form-control",
                    attrs: { cols: "30", rows: "10" },
                    domProps: { value: _vm.item.note },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.item, "note", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "" } }, [_vm._v(" Tags ")]),
                    _c("b-form-tags", {
                      staticClass: "form-control",
                      staticStyle: { height: "229px!important" },
                      attrs: {
                        separator: ",;",
                        "no-add-on-enter": "",
                        placeholder: "",
                      },
                      model: {
                        value: _vm.item.tags,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "tags", $$v)
                        },
                        expression: "item.tags",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c("BtnSaveAndBack", {
        attrs: {
          routerBack: "products-online-list",
          variant: "success",
          submited: _vm.submited,
          text: "Cadastrar",
          subtext: "Aguarde...",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <b-card no-body class="mb-1">
      <b-card-header>
        <div>
          <b-card-title class="mb-1">
            Dados gerais
          </b-card-title>
          <b-card-sub-title>
            Os dados inseridos abaixo serão utilizados ao emitir a nota fiscal.
          </b-card-sub-title>
        </div>
      </b-card-header>
      <b-card-body class="pl-2 pr-2">
        <div class="form-row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Fornecedor
              </label>
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                v-model="item.provider"
                placeholder="Digite o nome"
                :options="optionsProviders"
                @search="fetchProviders"
                :class="{ 'is-invalid': $v.item.provider.$error }"
              >
                <span
                  slot="no-options"
                >
                  Nenhum registro encontrado
                </span>
              </v-select>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group mb-0 mb-m-1">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Vencimento
              </label>
              <input type="text" placeholder="dd/mm/aaaa" v-model="item.issue_date" v-mask="['##/##/####']"  class="form-control">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Categoria
              </label>
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                v-model="item.provider"
                placeholder="Digite o nome"
                :options="optionsProviders"
                @search="fetchProviders"
                :class="{ 'is-invalid': $v.item.provider.$error }"
              >
                <span
                  slot="no-options"
                >
                  Nenhum registro encontrado
                </span>
              </v-select>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="">
                Nº Documento
                <i class="bi bi-question-circle" v-b-tooltip.hover.top="'Referenciar documento fora da Seducar.'"></i>
              </label>
              <input type="text" class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group mb-0 mb-m-1">
              <label for="">
                Data de emissão
              </label>
              <input type="text" placeholder="dd/mm/aaaa" v-model="item.issue_date" v-mask="['##/##/####']"  class="form-control">
            </div>
          </div>          
        </div>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <div>
          <b-card-title class="mb-1">
            Observações gerais
          </b-card-title>
          <b-card-sub-title>
            Caso tenha mais alguma informação ou observação sobre a empresa
          </b-card-sub-title>
        </div>
      </b-card-header>
      <b-card-body class="pl-2 pr-2">
        <div class="form-row">
          <div class="col-md-9">
            <div class="form-group">
              <textarea v-model="item.note" class="d-block form-control" cols="30" rows="10"></textarea>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="">
                Tags
              </label>
              <b-form-tags
                separator=",;"
                no-add-on-enter
                class="form-control"
                placeholder=""
                v-model="item.tags"
                style="height: 229px!important;"
              />
            </div>
          </div>
        </div>        
      </b-card-body>
    </b-card>
    <BtnSaveAndBack
      routerBack="products-online-list"
      variant="success"
      :submited="submited"
      text="Cadastrar"
      subtext="Aguarde..."
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BCardBody,
  BInputGroup,
  BFormInput,
  VBTooltip,
  BFormTags,
  BInputGroupAppend,
} from 'bootstrap-vue'

import { required } from "vuelidate/lib/validators";

import vSelect from "vue-select";
import "swiper/css/swiper.css";

import Ripple from 'vue-ripple-directive';

import BtnSaveAndBack from '@/components/button/SaveAndBack'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BtnSaveAndBack,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    VBTooltip,
    BCardSubTitle,
    BFormCheckbox,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    vSelect,
    BFormTags,
  },
  data() {
    return {
      submited: false,
      item: {
        tags: [],
        provider: '',
        issue_date: '',
        total: '',
        due_date: '',
        subcategory: '',
        doc_number: '',
        occurrence: '',
        status: 0,
        note: '',
      },
      optionsProviders: [],
    }
  },
  validations: {
    item: {
      provider: {
        required,
      },
      issue_date: {
        required,
      },
      category: {
        required,
      },
    },
  },
  watch: {
    'item.business': {
      handler(e) {
        this.typeProvider = e ? `<i class="icon-checkbox bi bi-building"></i> Jurídica` : `<i class="icon-checkbox bi bi-person"></i> Física`;
      },
    },
  },
  methods: {
    async fetchProviders(term) {
      if (term.length > 2) {
        this.$store.dispatch("Provider/search", term).then((res) => {
          this.optionsProviders = res.data
        });
      }
    },
  }
}
</script>